.CustomSwitch-container {
  display: flex;
  max-width: 200px;
  /* margin: 10px; */
  border-radius: 6px;
  border: 3px solid #e01e59;
  /* border: 3px solid #a5175e; */
  /* padding: 2px; */
}

.CStab {
  cursor: pointer;
  padding: 10px;
  text-transform: capitalize;
  color: black;
}

.CStab:hover {
  background-color: #f0f0f0;
  color: black;
}

.CSActive {
  background-color: #e01e59;
  /* background-color: #a5175e; */
  color: #fff;
  cursor: pointer;
  padding: 10px;
  text-transform: capitalize;
}
