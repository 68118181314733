.container {
  margin-top: 55px;
  display: flex;
  background: #ffffff;
  justify-content: space-between;
  /* height: 100%; */
  min-height: 90vh;
}

.chatList {
  border-right: 1px solid #e6e6e6;
  display: flex;
  /* height: 100%; */
  max-width: 380px;
  background: #ffffff;
  flex-direction: column;
}
.midleContainer {
  width: 100%;
  display: flex;
  /* height: auto; */
  flex-direction: column;
  justify-content: space-between;
}
.rightContainer {
  /* width: 500px; */
  border-left: 1px solid #e6e6e6;
  display: flex;
  height: 100%;
  background: #ffffff;
  flex-direction: column;
}

.container2 {
  height: 100%;
}

.tabs {
  list-style-type: none;
  display: flex;
  width: 380px;
  justify-content: space-around;
  border-bottom: 1px solid #c1c1c1;
  padding-left: 0%;
}
.selectedTab {
  color: #de4467;
  border-bottom: 2px solid #de4467;
  padding-bottom: 5px;
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
}
.tab {
  padding-bottom: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #979797;
  cursor: pointer;
}

.userHeading {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #464646;
}

.dotsButton {
  width: 33px;
  height: 25px;
  background: #f3f3f3;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0;
  cursor: pointer;
  color: #2e353a;
}
.userHeadder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  height: 70px;
  border-bottom: 1px solid #c1c1c1;
}
.rightMContainer {
  width: 100vw;
  display: flex;
  height: auto;
  background: #ffffff;
  flex-direction: column;
}

.MchatList {
  /* border-right: 1px solid #e6e6e6; */
  display: flex;
  height: 90vh;
  width: 100vw;
  background: #ffffff;
  flex-direction: column;
}
.Mcontainer {
  margin-top: 55px;
  /* display: flex; */
  background: #ffffff;
  /* justify-content: space-between; */
  /* height: 100%; */
  min-height: 90vh;
}
.dontShow {
  display: none;
}
.show {
  display: block;
}
