/* .CampaignNav {
  padding: 2% 2% 0 2%;
  border-bottom: 1px solid #eddcdc;
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
} */
/* .CampaignNav nav {
  width: 50%;
} */
/* .CampaignNav a {
  text-decoration: none;
  margin-right: 3%;
  font-weight: 500;
  color: #b5b5b5;
}
.CampaignNav .active {
  color: #464646;
  text-decoration: none;
  margin-right: 3%;
  font-weight: 500;
  border-bottom: 2px solid #de4467;
  padding-bottom: 28px;
} */

.mainCtr {
  padding: 2.5%;
  min-height: 80vh;
}
.mainCtr .title {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 31px;
  color: #464646;
}

.mainCtr .subTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #b5b5b5;
  margin-bottom: 20px;
}

.layoutbox {
  /* width: 654px; */
  height: 340px;
  background: #f5f5f5;
  border-radius: 5px;
}
.layoutbox2 {
  /* width: 310px; */
  height: 155px;
  background: #f5f5f5;
  border-radius: 5px;
}
.Sortby {
  display: flex;
  width: 115px;
  height: 39px;
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 5px;
  padding: 10px 11px;
}
.Sortby label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: #b3b3b3;
}
.Sortby select {
  border: none;
  cursor: pointer;
}
.Sortby select option :focus {
  background-color: #de4467;
}
/* .modal {
  position: absolute;
width: 991px;
height: 617px;
left: 225px;
top: 243px;

  background: #ffffff;
  border-radius: 5px;
  padding: 40px;
} */
/* .modalTitle {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  padding: 40px 0 26px 40px;
  padding-bottom: 26px;
  border-bottom: 1px solid #e1e1e1;
  color: #464646;
} */

.templateReview {
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.inPutLableCtr {
  display: flex;
  justify-content: space-between;
}
.inPutLableCtr .createNewCamp {
  display: flex;
  align-items: center;
  color: #007913;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 12px;
  cursor: pointer;
  text-transform: capitalize;
}
/* .modal .modalContent .form .submitBtn {
  position: absolute;
  width: 228px;
  left: 40px;
  top: 500px;
} */
/* .chatContact{
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
} */

.campaignName {
  padding: 5px 20px;
  font-weight: lighter;
}
.analysis {
  padding: 20px;
  width: 100%;
}
.totalmessage {
  padding: 20px 20px 0px 20px;
  font-size: 16px;
}
