.conversation {
  margin: 10px;
  padding: 5px;
  display: flex;
}
.profileStat {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 380px;
}
.conversation :hover {
  cursor: pointer;
}
.conversation .Head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
}
.conversation img {
  width: 39px;
  height: 39px;
  border-radius: 50%;
  margin: 0 5px 0 0;
}
.conversation .name {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}
.conversation .timeStamp {
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: right;
  color: #979797;
}
.conversation .msgcount {
  background-color: #25d366;
  color: #ffffff;
  font-size: 8px;
  padding: 2px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
}

.conversation .LatestMessage {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #949494;
  word-wrap: break-word;
}
.message a {
  color: #263238;
  text-decoration: underline;
}
.online {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #27ca40;
  margin-left: -4px;
  margin-top: 3px;
  z-index: 2;
}

.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 8px;
}

.dropdown-content {
  display: block;
  position: absolute;
  z-index: 1;
  top: calc(100% + 5px);
  right: -20px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 8px;
  width: 220px;
}

.dropdown-content a {
  color: #000;
  display: block;
  padding: 8px;
  text-decoration: none;
}

.dropdown-content a:hover {
  background-color: #f2f2f2;
}

.dropdown-item {
  color: #000;
  padding: 8px;
  cursor: pointer;
}

.dropdown-item.selected {
  background-color: #f2f2f2; /* Add your selected background color here */
}

.dropdown-item:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}

.chatContact {
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dropdown-filter {
  color: black;
  justify-content: center;
  font-weight: bold;
  font-size: smaller;
  display: flex;
  margin-bottom: 5px;
}
