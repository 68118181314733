.Body {
  background-color: " #FFFFFF";
  /* font-family: "Aeonik"; */
}
* {
  box-sizing: border-box;
  margin: 0;
}
html,
body {
  height: 100%;
}
#root {
  background-color: #fff;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  height: 100%;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background-color: #ffffff;
}
::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #464646;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay * {
  pointer-events: none;
}

.preview {
  background-color: #ffff;
  max-width: 300px;
  width: 100%;
  border-radius: 0 10px 10px 10px;
  /* min-height: 100px; */
  min-width: 200px;
  position: relative;
  padding-left: 20px;
}
.preview::before {
  content: "";
  position: absolute;
  left: -10px;
  border-bottom: 20px solid transparent;
  border-right: 20px solid #ffff;
}
.preview p:nth-child(1) {
  font-weight: bold;
}
.preview p:nth-child(2) {
  margin: 10px 0;
}
.previewMCtr {
  padding: 5px 5px 5px 0;
}

/* Campaign css */
.CampaignNav {
  padding: 2% 2% 0 2%;
  border-bottom: 1px solid #eddcdc;
  /* padding-bottom: 15px; */
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  padding-bottom: 40px;
}
.CampaignNav nav {
  width: 100%;
}
.CampaignNav a {
  text-decoration: none;
  margin-right: 3%;
  font-weight: 500;
  color: #b5b5b5;
  max-width: 150px;
}
.CampaignNav .active {
  color: #464646;
  text-decoration: none;
  margin-right: 3%;
  font-weight: 500;
  border-bottom: 2px solid #de4467;
  padding-bottom: 28px;
  max-width: 150px;
}

.mainCtr {
  padding: 2.5%;
  min-height: 80vh;
}
.mainCtr .title {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 31px;
  color: #464646;
}

.mainCtr .subTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #b5b5b5;
  margin-bottom: 20px;
}

.layoutbox {
  /* width: 654px; */
  height: 340px;
  background: #f5f5f5;
  border-radius: 5px;
}
.layoutbox2 {
  /* width: 310px; */
  height: 155px;
  background: #f5f5f5;
  border-radius: 5px;
}
.Sortby {
  display: flex;
  width: 115px;
  height: 39px;
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 5px;
  padding: 10px 11px;
}
.Sortby label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: #b3b3b3;
}
.Sortby select {
  border: none;
  cursor: pointer;
}
.Sortby select option :focus {
  background-color: #de4467;
}
/* .modal {
  position: absolute;
width: 991px;
height: 617px;
left: 225px;
top: 243px;

  background: #ffffff;
  border-radius: 5px;
  padding: 40px;
} */
.modalTitle {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  /* padding: 40px 0 26px 40px; */
  padding-bottom: 26px;
  /* border-bottom: 1px solid #e1e1e1; */
  color: #464646;
}

.templateReview {
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.inPutLableCtr {
  display: flex;
  justify-content: space-between;
}
.inPutLableCtr .createNewCamp {
  display: flex;
  align-items: center;
  color: #007913;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 12px;
  cursor: pointer;
  text-transform: capitalize;
}
/* .modal .modalContent .form .submitBtn {
  position: absolute;
  width: 228px;
  left: 40px;
  top: 500px;
} */

.customMTableToolbar {
  padding: 15px 10px;
  display: flex;
  justify-content: space-between;
}

/* /Services pages */
.pageInNav {
  margin-top: 55px;
  max-height: 76px;
  border-bottom: 1px solid #eddcdc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
}
.pageInNav p {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  color: #464646;
}
.pageH {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  color: #464646;
}

.matToolbar {
  display: flex;
  align-items: center;
}
/* .matToolbar div {
} */
.table {
  margin: 20px 50px;
}

.table .profilePic {
  width: 39px;
  height: 39px;
  border-radius: 50%;
  margin-right: 18px;
}
.table .column-1 {
  display: flex;
  max-width: 300px;
}
.table .column-1 p:first-of-type {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #464646;
  margin: 4px 0;
}
.table .column-1 p:last-of-type {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #8e8e8e;
}
.table .column-2 .Icon {
  color: #5f6368;
  max-height: 16px;
}
.table .column-2 p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #263238;
  display: flex;
  align-items: center;
  margin: 5px 0;
}
.table .column-3 {
  display: flex;
  flex-wrap: wrap;
}
/* .table .column-3 .cloasButton.closeIcon {
  font-size: 5px;
} */

.closeBtn {
  padding: 0;
  background: #a4334d;
  border: 2px solid #a4334d;
  border-radius: 50%;
  padding: 2px;
  margin-right: 5px;
}
.table .column-3 .icon123 {
  width: 9px;
  height: 9px;
}
.customToolbar {
  padding: 15px 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.table .column-3 p {
  background: rgba(222, 68, 103, 0.08);
  margin: 10px;
  border-radius: 60px;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.03em;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(70, 70, 70, 0.8);
}
.Icon2 {
  width: 16px;
}
.Icon3 {
  width: 16px;
  color: #ffffff;
}
.table .column-4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #263238;
}
.table .column-5 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.table .column-5 .Icon {
  max-height: 20px;
}

.table .column-5 p {
  color: #de4467;
  background-color: #fff8f8;
  width: 26px;
  height: 26px;
  border-radius: 5px;
  margin: 12.5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mCtr {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  min-width: 20%;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 2%;
  width: 450px;
  max-height: 95%;
  overflow-y: auto;
}
.modal .popupContent {
  flex-direction: column;
}
.longModal {
  min-width: 20%;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 2%;
  max-width: 80%;
  max-height: 95%;
  overflow-y: auto;
}

.rotate-button {
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ededed;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.rotate-button:hover {
  background-color: #e0e0e0;
}
.rotate-button.rotate {
  transform: rotate(45deg);
}

.icon {
  font-size: 24px;
  border-radius: 50%;
}

.ActionsBTNCTR {
  position: absolute;
  right: 0px;
  top: 10px;
  display: flex;
  flex-direction: column;
}

.suggetionsCtr {
  position: relative;
  width: 385px;
  background-color: #ffffff;
  z-index: 9999;
  border-radius: 4px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  max-height: 300px;
  overflow-x: auto;
}
.addIconBtn {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #316474;
  background-color: #42cba5;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #316474;
}
.addIconBtn:hover {
  background-color: #25d366;
}

.removeIconBtn {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #510920;
  color: #510920;
  background-color: #df1d5a;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.removeIconBtn:hover {
  background-color: #db342c;
}

.countryDropDownCss span:first-of-type {
  margin-left: 30px;
}

/* react Flow */

/* .react-flow__node-custom {
  font-size: 10px;

  background: #ffff;
  color: #222;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 8px;
  padding: 5px;
} */
.custom-node__outerBody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 180px;
  max-width: 300px;
  min-height: 200px;
  font-size: 10px;
  background: #ffff;
  color: #222;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 8px;
}
.custom-node__outerBody:active {
  border: 1px solid #df1d5a;
}
/* .onClickBorder {
  border: 1px solid #df1d5a;
} */
/* .custom-node__HnMCtr {
  background-color: #42cba5;
} */
.react-flow__node-custom .react-flow__handle {
  /* top: 24px; */
  right: -15px;
  width: 6px;
  height: 10px;
  border-radius: 2px;
  background-color: #da1e7a;
}
.custom-node__btn {
  position: relative;
  margin-bottom: 10px;
}

.custom-node__header {
  padding: 15px 15px;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  justify-content: space-between;
}

/* .custom-node__buttons {
  padding: 10px;
  background-color: #8e8e8e;
} */

/* .message {
  position: relative;
} */

.message::before {
  content: "";
  position: absolute;
  top: 0;
  left: -15px;
  border-top: 0 solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 15px solid #ffff;
}

.custom-node__starting_card {
  background: linear-gradient(272.65deg, #383194 0%, #da1e7a 100%);
  border-radius: 50%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contactComment {
  min-width: 225px;
  max-width: 450px;
  height: auto;
  padding: 10px 20px 20px 20px;
  border-radius: 10px;
  word-wrap: break-word;
  background-color: #eeee;
  color: black;
  /* white-space: pre-wrap; */
  margin: 20px 5px 5px 5px;
}
.userComment {
  min-width: 225px;
  max-width: 450px;
  height: auto;
  padding: 10px 20px 20px 20px;
  border-radius: 10px;
  word-wrap: break-word;
  background-color: #feffcd;
  color: #747474;
  /* white-space: pre-wrap; */
  margin: 20px 5px 5px 5px;
}

.commentTimeStamp {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  /* text-align: right; */
  color: #979797;
  /* padding: 0 20px; */
  /* width: 225px; */
  padding: 0px 20px 0px 20px;

  /* position: relative; */
  /* right: 0; */
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  .table {
    margin: 10px 15px;
  }
  .customToolbar {
    padding: 10px 5px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
  .pageInNav {
    margin-top: 55px;
    max-height: 76px;
    border-bottom: 1px solid #eddcdc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  .modal {
    min-width: 100%;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 2%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
  }

  .table {
    margin: 10px;
  }

  .CampaignNav {
    /* padding: 2% 2% 0 2%; */
    border-bottom: 1px solid #eddcdc;
    display: flex;
    justify-content: space-between;
    margin-top: 65px;
    padding-bottom: 40px;
  }
  .preview {
    background-color: #ffff;
    max-width: 250px;
    width: 100%;
    border-radius: 0 10px 10px 10px;
    /* min-height: 100px; */
    min-width: 200px;
    position: relative;
    padding-left: 20px;
  }
}
