.Footer {
  background: #0d0821;
  color: #ffff;
  height: auto;
  padding: 3%;
  /* margin-left: 5px; */
  /* width: 90vw; */
}
.FooterHeading {
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 700;
  font-size: 24.464px;
  line-height: 30px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.5em;
  color: #da1e7a;
  /* min-width: 300px; */
}
.SocialIcons {
  list-style: none;
  display: flex;
  margin-top: 40px;
}

.SocialIcons li {
  margin: 0 5px;
  width: 50px;
  height: 50px;
  left: 127px;
  top: 4813px;
  border-radius: 50%;
  background: #7e2989;
  display: flex;
  justify-content: center;
  align-items: center;
}
.link {
  text-decoration: none;
  color: #ffff;
  /* margin: 20px 0; */
}
/* .Footer .ctr {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: auto;
  gap: 30px;
} */
.FooterH2 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* text-align: center; */
  color: #da1e7a;
  min-width: 300px;
}

.Policy {
  list-style: none;
  padding: 0;
}
.Policy li {
  margin-top: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */
  color: #ffffff;
}

 .comparison  {
  color: #ffffff;
  font-weight: 400;
  font-size: "15px";
  line-height: 18px;
  cursor: pointer;
  text-decoration: none;
} 

.comparison-list{
  list-style: none;
  padding-top: 10px;
}