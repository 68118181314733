.empty-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
  }
  
  .empty-page h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .empty-page p {
    display: flex; 
    justify-content: center; 
    align-items: center;
    font-size: 16px;
    color: gray;
  }
  