.mainNavBar {
  display: flex;
  justify-content: space-between;
  max-height: 55px;
  position: fixed;
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  top: 0;
  width: 100%;
  z-index: 10;
  align-items: center;
  /* padding: 10px; */
}
.logosvg {
  max-width: 116px;
}
.logoDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 20px 0 20px 20px; */
}
.Navmenu {
  margin: 20px 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 0;
  padding-inline-start: 0;
}
.normalNav {
  color: #464646;
  font-weight: 400;
  font-size: "15px";
  border-radius: "6px";
  /* padding-left: 30px; */
  line-height: 18px;
  cursor: pointer;
  text-decoration: none;
}
.normalNav.active {
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #464646;
  /* padding-left: 30px; */
  cursor: pointer;
}
.normalauthNav {
  border: 3px solid #301447;
  color: #301447;
  font-weight: 400;
  font-size: 15px;
  border-radius: 8px;
  padding: 8px 20px;
  height: 15px;
  line-height: 18px;
  cursor: pointer;
  text-decoration: none;
  margin-right: 20px;
}
.normalauthNav:hover {
  color: white;
  background-color: #301447;
}
.boldNav {
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #464646;
  padding-left: 30px;
  cursor: pointer;
}

.activeNavLink {
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #464646;
  /* padding-left: 30px; */
  cursor: pointer;
}
.navProfileImg {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  /* margin: 10px; */
}
.profileBtn {
  /* background: #383194 10%; */

  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  color: #464646;
  /* border-radius: 20px; */
  padding: 6px 10px 6px 10px;
}
/* .css-vip2mg-MuiButtonBase-root-MuiButton-root,
.css-38yo7f-MuiButtonBase-root-MuiButton-root,
.css-1qe6zu4-MuiButtonBase-root-MuiButton-root,
.css-3d3f6q-MuiButtonBase-root-MuiButton-root,
.css-rqpeoy-MuiButtonBase-root-MuiButton-root,
.css-1vvtws-MuiButtonBase-root-MuiButton-root {
  background: linear-gradient(272.65deg, #383194 0%, #da1e7a 100%);
  border-radius: 5px;
} */

.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  width: 30px;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  /* margin-right: "10px"; */
}

.hamburger span {
  display: block;
  height: 3px;
  width: 100%;
  border-radius: 2px;
  background-color: #383194;
  transition: transform 0.3s ease-out;
}

.hamburger span:nth-child(1) {
  transform-origin: top;
}

.hamburger span:nth-child(3) {
  transform-origin: bottom;
}

.hamburger.open span:nth-child(2) {
  transform: translateY(3px) rotate(45deg);
}

.hamburger.open span:nth-child(1) {
  opacity: 0;
}

.hamburger.open span:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}
