.policyCtrMain {
  background: "#fffafb";
  min-height: 100vh;
  margin-top: 55px;
}
.policyCtr {
  margin: 2% auto 5% auto;
  max-width: 70%;
  padding: 10px 0;
}

/* .policyCtr h2 {
  text-align: center;
} */
.policyCtr h3 {
  margin-bottom: 15px;
}
.policyCtr p {
  margin: 10px 0;
}
.policyCtr .bold {
  font-weight: bold;
}
.policyCtr a {
  color: black;
  text-decoration: none;
}
.policyCtr a:hover {
  text-decoration: underline;
}
