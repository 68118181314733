.comparison-body{
    background-color: #FFFAFB !important;
}

.scrollable-div { 
    height: 100%;
    margin: 10px;
    margin-bottom: 50px !important;
  }

.title{
    justify-content: center;
    display: flex;
    margin-top: 2rem;
}

.brand-title{
    color: #000;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 20px;
    margin-left: 10px;
}

.content-horizontal{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.text {
    margin-top: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
}
.text p{
    color: #DA1E7A;
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}
.text span{
    color: #000;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}
.content-pinaippu{
    margin-top: 50px;
    display: flex;
    padding: 0 300px;
    height: 204px;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.content-pinaippu p{
    color: rgba(0, 0, 0, 0.67);
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}

.challenges{
    display: flex;
    justify-content: space-between;
}

.challenges-pros{
    margin-left:  150px;
    margin-top: 20px;
    border-radius: 15px;
    border: 5px solid rgba(191, 33, 126, 0.2);
    backdrop-filter: blur(15px); 
    background: rgba(191, 33, 126, 0.04);
    width: 500px;
    /* height: 700px; */
    flex-shrink: 0;
}

.challenges-pros p {
    margin: 30px;
    color: #BF217E;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}

.challenges-pros ul {
    list-style-type: disc; 
    margin-left: 10px; 
}

.challenges-pros li {
    margin-bottom: 30px;
    margin-right:30px ;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}


.challenges-cons{
    margin-right:  150px;
    margin-top: 20px;
    border-radius: 15px;
    backdrop-filter: blur(15px); 
    border: 5px solid rgba(129, 40, 136, 0.3);
    background: rgba(129, 40, 136, 0.04);
    width: 500px;
    /* height: 700px; */
    flex-shrink: 0;
}

.challenges-cons p {
    margin: 30px;
    color: #812888;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}

.challenges-cons ul {
    list-style-type: disc; 
    margin-left: 10px; 
}

.challenges-cons li{
    margin-bottom: 30px;
    margin-right:30px ;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}

.choose-pinaippu {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 100px;
    
}
  
.choose-pinaippu ul{
     list-style-type: disc; 
     margin-left: 10px; 
}

.choose-pinaippu li{
    margin-bottom: 30px;
    margin-right:30px ;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}

.choose-pinaippu-images{
    display: flex;
    flex-direction: column;
    margin: 0 50px;
}

.choose-pinaippu-images p{
    margin-top: 10px;
    text-align: center;
    color: #812888;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}

.choose-pinaippu-roadmap{
    margin: 80px;
}

.choose-pinaippu-roadmap p{
    margin-top: 10px;
    text-align: center;
    color: #812888;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}
.images{
    margin: 60px !important;
    margin-bottom: 0 !important;
    display: flex;
    flex-direction: column;
}

.pinaippu-images{
    display: flex;
    flex-direction: row;
    margin: 0 auto; /* Center the div horizontally */
    max-width: 100%;
}

.pinaippu-solution{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 150px;
}

.pinaippu-solution p{
    align-items:center;
    color: #000;
    font-size: 30px;
    font-style: normal;
    font-weight: 445;
    line-height: normal;
    text-transform: capitalize;
}

/* Table styles */
.material-table {
    margin: auto;
    max-width: 100%;
}

/* Table header */
.MuiTableCell-head {
    background-color: #f3eeef;
    border: 1px solid #dddbdb;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize;
    line-height: normal;
    border-bottom: 1px solid rgba(121, 6, 78, 0.90);
    color: rgba(121, 6, 78, 0.9);
}

/* Table cells */
.MuiTableCell-body {
    border: 1px solid #dddbdb;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
}

/* Pros and Cons sections */
.challenges-pros,
.challenges-cons {
    margin-top: 20px;
    border-radius: 15px;
    backdrop-filter: blur(15px);
    width: 500px;
    flex-shrink: 0;
}

.challenges-pros p,
.challenges-cons p {
    margin: 30px;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}

.challenges-pros ul,
.challenges-cons ul {
    list-style-type: disc;
    margin-left: 10px;
}

.challenges-pros li,
.challenges-cons li {
    margin-bottom: 30px;
    margin-right: 30px;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}

/* Conclusion and selection */
.choose-pinaippu {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 100px;
}

.choose-pinaippu ul {
    list-style-type: disc;
    margin-left: 10px;
}

.choose-pinaippu li {
    margin-bottom: 30px;
    margin-right: 30px;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}

/* Images and solution */
.images {
    margin: 60px !important;
    margin-bottom: 0 !important;
    display: flex;
    flex-direction: column;
}

.pinaippu-images {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    max-width: 100%;
}

.pinaippu-solution {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 150px;
}

.pinaippu-solution p {
    align-items: center;
    color: #000;
    font-size: 30px;
    font-style: normal;
    font-weight: 445;
    line-height: normal;
    text-transform: capitalize;
}

.container {
    background-color: #FFFAFB;
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box {
    width: 25%;
    height: 40%;
    position: absolute;
    font-size: 22px;
    display: flex;
    justify-content: center;
    padding: 20px;
    border: #000 solid 1px;
    overflow: auto;
    padding-bottom: 20px;
}

.central-box {
    background: linear-gradient(135deg, #f59fde, #a584f2);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.top-left {
    background: linear-gradient(315deg, #FAE2E8, #FFB8EA);
    top: 5%;
    left: 22.5%;
}

.top-right {
    background: linear-gradient(225deg, #FFB8EA, #FAE2E8);
    top: 5%;
    right: 22.5%;
}

.bottom-left {
    background: linear-gradient(225deg, #FAE2E8, #FFB8EA);
    bottom: 5%;
    left: 22.5%;
}

.bottom-right {
    background: linear-gradient(315deg, #FFB8EA, #FAE2E8);
    bottom: 5%;
    right: 22.5%;
}