/* .pageInNav {
  margin-top: 55px;
  max-height: 76px;
  border-bottom: 1px solid #eddcdc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
}
.pageInNav p {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #464646;
} */
.table .profilePic {
  width: 39px;
  height: 39px;
  border-radius: 50%;
  margin-right: 18px;
}
.table .column-1 {
  display: flex;
  max-width: 300px;
}
.table .column-1 p:first-of-type {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #464646;
  margin: 4px 0;
}
.table .column-1 p:last-of-type {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #8e8e8e;
}
.table .column-2 .Icon {
  color: #5f6368;
  max-height: 16px;
}
.table .column-2 p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #263238;
  display: flex;
  align-items: center;
  margin: 5px 0;
}
.table .column-3 {
  display: flex;
  flex-wrap: wrap;
}
.table .column-3 .cloasButton.closeIcon {
  font-size: 5px;
}

.closeBtn img {
  padding: 0;
  background: #a4334d;
  border: 2px solid #a4334d;
  border-radius: 50%;
  padding: 2px;
  margin-right: 5px;
}
.table .column-3 .icon123 {
  width: 9px;
  height: 9px;
}
.table .column-3 p {
  background: rgba(222, 68, 103, 0.08);
  margin: 10px;
  border-radius: 60px;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.03em;
  width: max-content;
  display: flex;
  justify-content: center;

  color: rgba(70, 70, 70, 0.8);
}

.table .column-4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #263238;
}
.table .column-5 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.column-5 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.table .column-5 .Icon {
  max-height: 20px;
}

.table .column-5 p {
  color: #de4467;
  background-color: #fff8f8;
  width: 26px;
  height: 26px;
  border-radius: 5px;
  margin: 12.5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.column-5 p {
  color: #de4467;
  background-color: #fff8f8;
  width: 26px;
  height: 26px;
  border-radius: 5px;
  margin: 12.5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mCtr {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  min-width: 20%;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 2%;
  border: 2px solid #ffffff;
}
.modal .popupContent {
  flex-direction: column;
}

.templateReview {
  margin-top: 10px;
  height: 90%;
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
