.profileImg {
  width: 98px;
  height: 98px;
  border-radius: 50%;
  margin: 0;
}

.profileCtr {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1px #c1c1c1;
}

.aboutContact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1px #c1c1c1;
  padding: 20px 0px;
}

.ctrHeading {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
  padding-left: 20px;
}

.fadeHeading {
  color: #909090;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin-right: 10px;
}

.userData {
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.impLinks .conversation {
  margin: 10px;
  padding: 5px;
  display: flex;
}

.impLinks .conversation img {
  width: 39px;
  height: 39px;
  border-radius: 50%;
  margin: 0 5px 0 0;
}

.impLinks .conversation .name {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.impLinks .conversation .message {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #949494;
}

.impLinks .message a {
  color: #263238;
  text-decoration: underline;
}