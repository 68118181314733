@font-face {
  font-family: "Aeonik";
  src: url("./Assets/fonts/Aeonik-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Aeonik";
  src: url("./Assets/fonts/Aeonik-RegularItalic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Aeonik";
  src: url("./Assets/fonts/Aeonik-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Aeonik";
  src: url("./Assets/fonts/Aeonik-BlackItalic.otf") format("opentype");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Aeonik";
  src: url("./Assets/fonts/Aeonik-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Aeonik-BoldItalic";
  src: url("./Assets/fonts/Aeonik-BoldItalic.otf") format("opentype");
  font-weight: bold;
  font-style: italic;
}
/* @font-face {
  font-family: "Aeonik";
  src: url("./Assets/fonts/Aeonik-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Aeonik";
  src: url("./Assets/fonts/Aeonik-LightItalic.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
} */
@font-face {
  font-family: "Aeonik";
  src: url("./Assets/fonts/Aeonik-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Aeonik";
  src: url("./Assets/fonts/Aeonik-MediumItalic.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
}
/* @font-face {
  font-family: "Aeonik";
  src: url("./Assets/fonts/Aeonik-Thin.otf") format("opentype");
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: "Aeonik";
  src: url("./Assets/fonts/Aeonik-ThinItalic.otf") format("opentype");
  font-weight: lighter;
  font-style: italic;
} */

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Aeonik", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Aeonik";
  letter-spacing: 0.9px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.no-underline {
  text-decoration: none !important;
}
