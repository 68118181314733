/* .loginNavBar {
  display: flex;
  justify-content: space-between;
  padding: 30px;
} */
.phoneInput {
  max-width: 321px;
  /* margin: auto; */
  margin: 10px;
  /* display: contents; */
}
.form-container {
  display: "flex" !important;
  justify-content: "center" !important;
}

.Tbutton {
  width: 200px;
  margin-left: 5px;
  background-color: "#DE4467";
}
.Tbutton :hover {
  background-color: "#ab344f";
}
/* ul li::marker {
  content: "▶";
  font-size: large;
  color: #ba68c8;
}
ul {
  margin-left: 10px;
}
ul li {
  padding-left: 15px;
  margin: 10px;
  max-width: 456px;
  font-size: 18px;
  font-weight: 400;
} */
.Fpara {
  color: #928585;
  font-size: 18px;
  max-width: 529px;
}
.heading {
  font-size: 22px;
  font-weight: 700;
  /* margin-top: 10%; */
}
