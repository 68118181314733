.Link {
  font-size: "18px";
  font-weight: "800";
  color: #000000;
}

.Link:hover {
  color: #de4467;
}
.teamImg {
  max-width: 360px;
  /* min-width: 300px; */
  /* width: 100%; */
  height: 200px;
  /* margin: auto; */
}
/* .centerContainer {
  width: 50%;
  flex-wrap: wrap;
  flex-direction: column;
  background: #ffff;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.centerall {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 4%;
}
.titel {
  font-size: 30px;
  font-weight: 500;
}
.sectionHeading {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
}
.sectionSubH {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 43px;
}
.sectionSubPera {
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  color: #979797;
  margin: 10px;
}
.subtitle {
  color: #8b8e96;
  margin: 10px;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}
.centerText {
  max-width: 685px;
  text-align: center;
}
.conversationImg {
  position: relative;
  /* left: 235px; */
  /* margin-right: auto; */
  right: 0%;
  bottom: 0;
  max-width: 70%;
  height: auto;
}
.reviewContainer {
  max-width: 580px;
  height: 150px;
  margin: auto;
  /* margin-top: 40px; */
  padding-top: 80px;
  width: 100%;
}
.user {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user-name {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.user-sub {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #9a9a9a;
}

.review {
  font-weight: 400;
  font-size: 26px;
  line-height: 31px;
  color: #000000;
  margin-bottom: 20px;
}
.quote {
  font-weight: 400;
  font-size: 20px;
  line-height: 31px;
  color: #9a9a9a;
  margin-bottom: 10px;
  font-style: italic;
}
.sideList {
  list-style-image: url("../../Assets/Line_39.svg");
  /* list-style-type: none; */

  margin: 0;
  padding: 0;
}
/* .sideList li::before {
  content: "";
  display: inline-block;
  width: 20px;
  max-width: 20px;
  height: 3px;
  background-color: #0d0821;
  margin-right: 10px;
  margin-bottom: 16px;
  vertical-align: middle;
  transform: translateY(-1px);
}
.sideList li {
  margin-bottom: 10px;
  margin-left: 10px; 
  display: flex;
  align-items: center;
}  */

.capsule {
  position: relative;
  width: 300px;
  height: 450px;
  /* border: 4px solid rgba(0, 0, 0, 0.1); */
  /* border-radius: 147.5px; */
  overflow: hidden;
  z-index: 0;
  transition: all 0.3s ease-in-out;
}

.capsule-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
  border-radius: inherit;
}

.capsule-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px 0;

  box-sizing: border-box;

  z-index: 2;
}

.capsule-text {
  margin-top: 280px;
  padding: 0 80px;
  z-index: 3;
  bottom: 0;
  color: #ffff;

  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
}

.SliderCtr {
  /* position: absolute; */
  /* top: 0;
  left: 0; */
  width: 100%;
  max-width: 873px;
  height: 482px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 873px 482px;
  z-index: 1;
  /* border-radius: inherit; */
  /* height: 100vh; */
  min-height: 482px;
  margin: 20px 0;
}
.SliderChCtr {
  position: relative;
  width: 272px;
  height: 353px;
  left: 600px;
  top: 110px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.MSliderChCtr {
  /* position: relative; */
  width: 272px;
  height: 353px;
  /* left: 600px;
  top: 110px; */
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.blog {
  max-width: 360px;
  margin: 1% 3%;
}
.blog2 {
  max-width: 358px;
  display: flex;
  margin: 10px 0;
}
.blog2,
.blog img {
  border-radius: 5px;
}
.blogHeading {
  font-weight: 700;
  font-size: 21px;
  line-height: 25px;

  color: #791656;
}

.blogSubHead {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  color: #301447;
}
.blogLink {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  text-align: right;
  color: #de4467;
  text-decoration: none;
}

.blogAuthorCircle {
  width: 24px;
  height: 24px;
  left: 115px;
  top: 4527px;
  border-radius: 50%;
  background: #e7e7e7;
}
.blogBottom {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  bottom: 0;
}
.blogAuthor {
  display: flex;
  align-items: center;
}

.featureImage {
  width: 110px;
  height:110px;
}

.featureDes {
  font-size: 25px;
  max-width: 80%;
  text-align: left;
  margin-top: 10px;
  margin-left: 20px;
}
.SignButton {
  background-color: #301447;
  margin: 10px;
  margin-right: 60px;
  color: white;
  align-items: center;
  border-radius: 5px;
  min-width: 120px;
  min-height: 50px;
  font-size:15px;
}

.customImage {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}