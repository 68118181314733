.pricingCtr {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 30px;
  flex-wrap: wrap;
}

.pricingCard {
  background-color: #ffff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  gap: 25px;
  padding: 40px 20px;
  width: 300px;
  height: 600px;
  /* max-height: 500px; */
  /* margin: 20px; */
  cursor: pointer;
  letter-spacing: 1px;
}

.pricingCard:hover {
  background: linear-gradient(150.65deg, #383194 0%, #da1e7a 100%);
  color: #ffff !important;
}
.pricingCard h2 {
  display: flex;
  align-items: center;
  gap: 8px;
}
/* .pricingCard li::marker {
  content: "✔";
  font-size: large;
  color: #ba68c8;
} */
.pricingCard ul {
  list-style-type: none;
}
.pricingCard li {
  padding-left: 8px;
  margin-bottom: 10px;
  display: flex;
  gap: 5px;
  width: 100%;
}

.discointChip {
  background: linear-gradient(150.65deg, #383194 0%, #da1e7a 100%);
  color: #ffff !important;
  padding: 3px 5px;
  border-radius: 10px;
  font-size: 8px;
}
