.SignupNavBar {
  display: flex;
  justify-content: space-between;
  padding: 30px;
}
.phoneInput {
  max-width: 321px;
  /* margin: auto; */
  margin-top: 0px;
  /* display: contents; */
}
.form-container {
  display: "flex" !important;
  justify-content: "center" !important;
}

.Tbutton {
  width: 200px;
  margin-left: 5px;
  background-color: "#DE4467";
}
.Tbutton :hover {
  background-color: "#ab344f";
}
.bullets li::marker {
  content: "▶";
  font-size: large;
  color: #ba68c8;
}
.bullets {
  /* margin-left: 10px; */
}
.bullets li {
  padding-left: 15px;
  margin: 10px;
  max-width: 456px;
  font-size: 18px;
  font-weight: 400;
}
.Fpara {
  color: #928585;
  font-size: 18px;
  max-width: 529px;
}
.heading {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 13px;
}

.enterH {
  /* font-family: "Aeonik"; */
  /* font-style: normal; */
  font-weight: 400;
  font-size: 20px;
  line-height: 38px;
  /* identical to box height, or 190% */
  text-align: center !important;
  color: #464646;
  margin: 0px;
}
.errorMsg {
  color: red;
  text-align: center;
  margin-top: -10px;
}
