.chatContent {
  overflow-y: auto;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column-reverse;
}

.chatContent .profilePic {
  width: 39px;
  height: 39px;
  border-radius: 50%;
  margin-top: 20px;
}

.chatContent .sender {
  display: flex;
  justify-content: right;
  padding: 0 6px 0 0;
}

.chatContent .received {
  display: flex;
  justify-content: left;
  padding: 0 0 0 6px;
}

.chatContent .sender .message {
  background: linear-gradient(272.65deg, #383194 0%, #da1e7a 100%);
  color: #ffffff;
  margin: 20px 5px 3px 5px;
}

.chatContent .sender .message ::before {
  border-bottom-left-radius: 0.8rem 0.7rem;
  border-right: 1rem solid #248bf5;
  right: -0.35rem;
  transform: translate(0, -0.1rem);
}

.chatContent .received .message {
  background-color: #f8f9fd;
  color: #747474;
  margin: 20px 5px 10px 5px;
}

.chatContent .message {
  height: auto;
  padding: 10px;
  border-radius: 10px;
  word-wrap: break-word;
}
.highlight {
  background-color: rgba(4, 170, 109, 0.1);
  transition: background-color 2s ease-in-out;
}
.chatContent .noteMessage {
  min-width: 225px;
  max-width: 450px;
  height: auto;
  padding: 10px 20px 20px 20px;
  border-radius: 10px;
  word-wrap: break-word;
  background-color: #feffcd;
  color: #747474;

  /* white-space: pre-wrap; */

  margin: 20px 5px 5px 5px;
}

.chatContent .noteTitle {
  font-size: 12px;
  margin-bottom: 15px;
  font-weight: 500;
}

.chatContent .timeStamp {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: right;
  color: #979797;
  /* padding: 0 20px; */
  width: 225px;
  /* position: relative; */
  /* right: 0; */
  display: flex;
  justify-content: flex-end;
}

.dayDivider p {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  width: 100%;
  text-align: center;
  /* border-bottom: 1px solid #ebecec;
  line-height: 0.1em;
  margin: 10px 0 20px; */
  color: #979797;
  display: flex;
  flex-direction: row;
  margin: 5px 0;
}

.inputDiv {
  display: flex;
  flex-direction: column;
}

.dayDivider p:before,
.dayDivider p:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #ebecec;
  margin: auto;
  margin: 5px;
}

.DnD_container {
  min-width: 300px;
  height: 200px;
  border: 2px dashed #da1e7a;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  flex-direction: column;
}

.DnD_container p {
  margin: 5px;
  text-align: center;
}

.emojiPicker {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.rotate-icon {
  transition: transform 0.3s;
}

.rotate {
  transform: rotate(90deg);
}

.templateReview {
  margin-top: 10px;
  height: 90%;
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* .inPutLableCtr {
  display: flex;
  justify-content: space-between;
} */

.toggleAction {
  position: absolute;
  right: 0px;
  bottom: 35px;
  display: flex;
  flex-direction: column;
}

.suggestions-container {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
}

.suggestions-box {
  position: absolute;
  bottom: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 500px;
  padding: 12px;
  margin-left: 5px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 1;
}

.suggestion-item {
  cursor: pointer;
  padding: 4px 8px;
  font-size: 14px;
}

.suggestion-item:hover {
  background-color: #e1e1e1;
}
